import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from "styled-components";
import Portfolio from '../components/Portfolio';

function Banner() {
  return (
    <Section id="portfolio">
        <SectionTopAngle />
        <Container>
            <Content>
                <Title>{'View My Portfolio'}</Title>
                <Text>{"Check out some of the websites I have worked on. From custom-built sites to some of the most popular content management systems. Browse around, get inspired, and please don't hesitate to contact me with any questions."}</Text>
            </Content>
            {/* <ButtonContainer>
                <a href="/portfolio">{'View Portfolio'}</a>
            </ButtonContainer> */}
        </Container>
        <Portfolio />
    </Section>
  );
}

export default Banner;

const shakeAnimation = keyframes`
    0% { transform: translateX(0); }
    20% { transform: translateX(-5px); }
    40% { transform: translateX(5px); }
    60% { transform: translateX(-5px); }
    80% { transform: translateX(5px); }
    100% { transform: translateX(0); }
`;

const Section = styled.section`
    background-color: #f5f5f5;
    border-bottom: solid 1px #eee;
    padding: 0 30px 30px 30px;
    position: relative;
`;

const Container = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1170px;
    padding: 0 20px;
`;

const Content = styled.div`
    @media (min-width: 768px) {
        width: 75%;
    }
`;

const Title = styled.h2`
    color: #333;
    display: block;
    font-size: 30px;
    font-weight: normal;
    line-height: 35px;
    margin-bottom: 12px;
`;

const Text = styled.p``;

const ButtonContainer = styled.div`
    a {
        background-color: #31b0d5;
        border: none;
        color: #fff;
        font-weight: 700;
        float: right;
        padding: 20px;
        text-align: center;
        text-transform: uppercase;

        &:hover {
            background: #fcad26;
            animation: ${shakeAnimation} .75s ease;
        }
    }
`;

const SectionTopAngle = styled.div`
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
    height: 25px;
    background-color: #f5f5f5;
    clip-path: polygon(0 0, 0% 0, 100% 100%, 100% 100%, 0 100%);
`;
