import React from 'react';
import styled from "styled-components";
import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import { Link } from 'react-router-dom';
import '../Stars.css';

window.onload = () => loadModel();

function loadModel() {
  const loader = new GLTFLoader();
  loader.load('WaveAnimation.glb',
    (gltf) => {
      setupScene(gltf);
      document.getElementById('avatar-loading').style.display = 'none';
    }, 
    (xhr) => {
      // document.getElementById('avatar-loading').innerText = `3D Model Loading...`
      console.log(`Loading model...`);
    }, 
    (error) => {
      console.log(error);
    }
  );
}

function setupScene(gltf) {
    const renderer = new THREE.WebGLRenderer({ 
      antialias: true, 
      alpha: true 
    });
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    
    const container = document.getElementById('avatar-container');
    renderer.setSize(container.clientWidth, container.clientHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    container.appendChild(renderer.domElement);

    // Camera setup
    const camera = new THREE.PerspectiveCamera(
      45, container.clientWidth / container.clientHeight);
    camera.position.set(0.2, 0.5, 1);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.enablePan = false;
    controls.enableZoom = false;
    controls.minDistance = 3;
    controls.minPolarAngle = 1.4;
    controls.maxPolarAngle = 1.4;
    controls.target = new THREE.Vector3(0, 0.75, 0);
    controls.dampingFactor = 0.25;
    controls.screenSpacePanning = false;
    controls.update();

    // Scene setup
    const scene = new THREE.Scene();

    // Lighting setup
    scene.add(new THREE.AmbientLight());

    const spotlight = new THREE.SpotLight(0xffffff, 20, 8, 1);
    spotlight.penumbra = 0.5;
    spotlight.position.set(0, 4, 2);
    spotlight.castShadow = true;
    scene.add(spotlight);

    const keyLight = new THREE.DirectionalLight(0xffffff, 2);
    keyLight.position.set(1, 1, 2);
    keyLight.lookAt(new THREE.Vector3());
    scene.add(keyLight);

    // Load avatar
    const avatar = gltf.scene;
    scene.add(avatar);

    // Load animations
    const mixer = new THREE.AnimationMixer(avatar);
    const clips = gltf.animations;
    const waveClip = THREE.AnimationClip.findByName(clips, 'waving');
    // const waveAction = mixer.clipAction(waveClip);

    if (waveClip) {
      const waveAction = mixer.clipAction(waveClip);
      waveAction.play();
    } else {
      console.error('Animation clip with name "..." not found.');
    }

    window.addEventListener('resize', () => {
      camera.aspect = container.clientWidth / container.clientHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(container.clientWidth, container.clientHeight);
    });

    const clock = new THREE.Clock();
    function animate() {
      requestAnimationFrame(animate);
      mixer.update(clock.getDelta());
      renderer.render(scene, camera);
    }

    animate();
    // waveAction.play();
}

// Controls
const handleColorChange = (event) => {
  document.documentElement.style.setProperty(`--${event.target.name}`, event.target.value);
};

const handleRangeChange = (event) => {
  const suffix = event.target.dataset.sizing || '';
  document.documentElement.style.setProperty(`--${event.target.name}`, event.target.value + suffix);
};

const handleMouseMove = (e) => {
  const mouseShadow = e.currentTarget;
  const text = mouseShadow.querySelector('span');

  const width = mouseShadow.offsetWidth;
  const height = mouseShadow.offsetHeight;
  const walk = 100; // 100px
  let { offsetX: x, offsetY: y } = e.nativeEvent;

  if (text && mouseShadow !== e.target) {
    x = x + e.target.offsetLeft;
    y = y + e.target.offsetTop;
  }

  const xWalk = Math.round((x / width * walk) - (walk / 2));
  const yWalk = Math.round((y / height * walk) - (walk / 2));

  if (text) {
    text.style.textShadow = `
      ${xWalk}px ${yWalk}px 0 rgba(255,0,255,0.7),
      ${xWalk * -1}px ${yWalk}px 0 rgba(0,255,255,0.7),
      ${yWalk}px ${xWalk * -1}px 0 rgba(0,255,0,0.7),
      ${yWalk * -1}px ${xWalk}px 0 rgba(0,0,255,0.7)
    `;
  }
};

function Animation() {
  return (
    <>
      <Container>
        <Stars id='stars'></Stars>
        <Stars id='stars2'></Stars>
        <Stars id='stars3'></Stars>
        <Section>
          <AvatarContainer id='avatar-container'>
            <AvatarLoading id='avatar-loading'></AvatarLoading>
          </AvatarContainer>
          <ContentContainer>
            <Title className="control">Hi there <Shake>👋</Shake></Title>
            <Title className="control">I'm <GradientText onMouseMove={handleMouseMove}>Andrew Dobrzanski</GradientText></Title>
            <Subtext className="control">I'm a web developer with a passion for crafting captivating and interactive digital experiences. I thrive on infusing creativity and innovation into my work, aiming to deliver engaging and memorable user interactions that leave a lasting impression.</Subtext>
            <a href="#footer">
              <LinkText className="btn-custom btn-3 icon-arrow-right">{'Contact Me'}</LinkText>
            </a>
          </ContentContainer>
        </Section>
      </Container>
      <ControlContainer>
        <Controls>
          <Control>
            <Label htmlFor="base">Text Color</Label>
            <Input id="base" type="color" name="base" defaultValue="#ffffff" onChange={handleColorChange} />
          </Control>
          <Control>
            <Label htmlFor="blur">Blur</Label>
            <Input id="blur" type="range" name="blur" min="0" max="5" defaultValue="0" data-sizing="px" onChange={handleRangeChange} />
          </Control>
        </Controls>
        <SectionBottomAngle />
      </ControlContainer>
    </>
  );
}

export default Animation;

const Container = styled.div`
  background-color: #282c34;
  color: white;
  display: flex;
  // filter: blur(var(--blur));
  margin: auto;
  min-height: 100vh;
  height: 100%;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: hidden;
`;

const Section = styled.div`
  align-items: center;
  display: flex;
  margin: 0 auto;
  max-width: 1200px;
  padding: 100px 20px;
  text-align: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Stars = styled.div``;

const AvatarContainer = styled.div`
  height: 575px;
  width: 100%;
  z-index: 1;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

const ContentContainer = styled.div`
  @media (min-width: 768px) {
    align-self: center;
    width: 50%;
  }
`;

const AvatarLoading = styled.div`
  font-size: 3em;
`;

const Title = styled.h1`
  font-size: 3em;
  font-weight: 500;
  padding: 0;
  margin: 0;
`;

const Subtext = styled.p`
  font-size: 2em;
  font-weight: 100;
  margin-top: 30px;
`;

const GradientText = styled.span`
  background-clip: text;
  background-image: linear-gradient(-45deg, rgb(252, 173, 38), rgb(49, 176, 213));
  color: transparent;
  transition: background-image 0.3s ease; /* Smooth transition */

  &:hover {
    background-image: linear-gradient(-45deg, rgb(49, 176, 213), rgb(252, 173, 38));
  }
`;

const Shake = styled.span`
  display: inline-block;
  transition: transform 0.3s ease;

  &:hover {
    animation: shake 0.5s ease-in-out infinite;
  }

  @keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
`;

const LinkText = styled.button`
  color: #fff;
`;

const ControlContainer = styled.div`
  background: #fcad26;
  padding: 20px;
  position: relative;
`;

const Controls = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 335px;
`;

const Control = styled.div``;

const Label = styled.label`
  color: #272727;
  font-weight: 700;
  margin-right: 10px;
`;

const Input = styled.input``;

const SectionBottomAngle = styled.div`
position: absolute;
bottom: -10px;
left: 0;
right: 0;
height: 40px;
background-color: #fcad26;
clip-path: polygon(0 0, 100% 0, 100% 50%, 50% 100%, 0 50%);
z-index: -1;
`;