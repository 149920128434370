import './App.css';
import Navbar from './components/Navbar';
import React from 'react';
import Banner from './components/Banner';
import Services from './components/Services';
import Animation from './components/Animation';
// import Portfolio from './components/Portfolio';
// import Contact from './pages/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <Navbar />
      <Animation />
      <Services />
      <Banner />
      {/* <Portfolio /> */}
      <Footer />
    </div>
  );
}

export default App;
